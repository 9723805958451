<template>
  <component :is="currentComponent"></component>
</template>

<script>
import { getRegisterComponent } from '@/util/getFileComponent';

export default {
  name: 'childView',
  data() {
    return {
      routerName: 'financialVerification/' + this.$route.name
    };
  },
  computed: {
    currentComponent() {
      return getRegisterComponent(this.routerName, this.regulator);
    }
  }
};
</script>
